export const onRouteUpdate = () => {
    // reach/router does not let the window scroll, they handle it to allow for accessibility, or something.
    // To scroll back up, we need to scroll that element, not window.
    const scrollElement = document.getElementsByClassName('tl-edges')[0];
    if (scrollElement) {
        scrollElement.scrollTo(0, 0)
    }
}

// We can't let Gatsby handle scrolling, otherwise it will keep the weird scroll behaviour of not actually moving
// the scroll position on page change.
export const shouldUpdateScroll = () => {
    return false;
};
