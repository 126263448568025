// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-equipe-tsx": () => import("../src/pages/equipe.tsx" /* webpackChunkName: "component---src-pages-equipe-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-tsx": () => import("../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-services-assurance-de-personne-tsx": () => import("../src/pages/services/assurance-de-personne.tsx" /* webpackChunkName: "component---src-pages-services-assurance-de-personne-tsx" */),
  "component---src-pages-services-autres-services-tsx": () => import("../src/pages/services/autres-services.tsx" /* webpackChunkName: "component---src-pages-services-autres-services-tsx" */),
  "component---src-pages-services-financement-hypothecaire-tsx": () => import("../src/pages/services/financement-hypothecaire.tsx" /* webpackChunkName: "component---src-pages-services-financement-hypothecaire-tsx" */),
  "component---src-pages-services-gestion-de-liquidites-tsx": () => import("../src/pages/services/gestion-de-liquidites.tsx" /* webpackChunkName: "component---src-pages-services-gestion-de-liquidites-tsx" */),
  "component---src-pages-services-placements-tsx": () => import("../src/pages/services/placements.tsx" /* webpackChunkName: "component---src-pages-services-placements-tsx" */)
}

